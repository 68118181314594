import './style/fonts.scss'
import 'swiper/swiper-bundle.css';
import './style/main.scss'


import Swiper, {Navigation, EffectFade, Autoplay, Lazy} from 'swiper';

Swiper.use([Navigation, EffectFade, Autoplay, Lazy]);

let prevScrollY = 0;

document.addEventListener('DOMContentLoaded', () => {

  // mainSliderHeight();

  new Swiper('.main-slider', {
    loop: true,
    effect: "fade",
    fadeEffect: {
      crossFade: true
    },
    preloadImages: false,
    lazy: {
      loadPrevNext: true
    },
    slidersPerView: 1,
    navigation: {
      nextEl: '.main-slider__nav-next',
      prevEl: '.main-slider__nav-prev',
    },
    autoplay: {
      disableOnInteraction: false,
      delay: 4000
    },
    speed: 2000
  });

  new Swiper('.live-slider', {
    loop: true,
    effect: "fade",
    fadeEffect: {
      crossFade: true
    },
    slidersPerView: 1,
    navigation: {
      nextEl: '.live-slider__nav-next',
      prevEl: '.live-slider__nav-prev',
    },
    // autoplay: {
    //   delay: 6000
    // },
    speed: 800
  });

  new Swiper('.products-slider', {
    loop: true,
    effect: "fade",
    fadeEffect: {
      crossFade: true
    },
    navigation: {
      nextEl: '.products-slider__nav-next',
      prevEl: '.products-slider__nav-prev',
    },
    autoplay: {
      disableOnInteraction: false,
      delay: 4300
    },
    speed: 2000
  });

  new Swiper('.product-gallery__slider', {
    loop: true,
    effect: "fade",
    fadeEffect: {
      crossFade: true
    },
    navigation: {
      nextEl: '.product-gallery__slider__nav-next',
      prevEl: '.product-gallery__slider__nav-prev',
    },
    autoplay: {
      disableOnInteraction: false,
      delay: 6000
    },
    speed: 2500
  });

  new Swiper('.product-card__slider', {
    loop: true,
    effect: "fade",
    fadeEffect: {
      crossFade: true
    },
    navigation: {
      nextEl: '.product-card__slider__nav-next',
      prevEl: '.product-card__slider__nav-prev',
    },
    // autoplay: {
    //   disableOnInteraction: false,
    //   delay: 6000
    // },
    speed: 700
  });

  // Infinite scroll start

  // let mouseDown = 0;
  // document.body.onmousedown = function() {
  //   mouseDown = 1;
  // }
  // document.body.onmouseup = function() {
  //   mouseDown = 0;
  // }

  const eventsLine = document.querySelector('.events__line');

  if (eventsLine) {
    const eventsListWrapper = document.querySelector(".events__list-wrapper");
    const eventsList = document.querySelector(".events__list");
    // const eventsListWidth = eventsList.clientWidth;

    const getCenterScrollPosition = () => {
      return eventsLine.scrollWidth / 2 - eventsLine.clientWidth / 2;
      // return eventsLine.scrollWidth / 3;
    }

    let eventsListCloneLeft = eventsList.cloneNode(true);
    eventsListCloneLeft.classList.add(
      "events__list--clone",
      "events__list--left"
    );

    let eventsListCloneRight = eventsList.cloneNode(true);
    eventsListCloneRight.classList.add(
      "events__list--clone",
      "events__list--right"
    );

    eventsListWrapper.insertBefore(eventsListCloneLeft, eventsList);
    eventsListWrapper.insertBefore(eventsListCloneRight, null);

    let avgWidth = eventsLine.scrollWidth - eventsLine.clientWidth;
    eventsLine.addEventListener("scroll", function () {
      if (eventsLine.scrollLeft >= avgWidth) {
        eventsLine.scrollLeft =
          eventsLine.scrollWidth / 3 +
          (eventsList.scrollWidth - eventsLine.clientWidth);
      }
      if (eventsLine.scrollLeft <= 0) {
        eventsLine.scrollLeft = eventsLine.scrollWidth / 3;
      }
    });

    eventsLine.scrollLeft = getCenterScrollPosition();

    // let timeout;
    let requestId;
    let request20;
    let requestArr = [];


    eventsLine.addEventListener('mouseover', function () {
      requestId = requestAnimationFrame(function animate() {
        // if (mouseDown) {
        //   request20 = requestAnimationFrame(animate);
        //   return
        // }
        eventsLine.scrollLeft += 2.5;
        request20 = requestAnimationFrame(animate);
        requestArr.push(request20);
      });
    })

    eventsLine.addEventListener('mouseout', function () {
      for (let req of requestArr) {
        cancelAnimationFrame(req);
      }
      cancelAnimationFrame(requestId);
      requestArr.length = 0;
    })
  }

  // Infinite scroll end

  const arrowDownBtn = document.querySelector('.arrow__down')

  if (arrowDownBtn) {
    arrowDownBtn.addEventListener('click', () => {
      // const vhInPX = document.querySelector('.main').scrollTop
      const element = document.querySelector('.main')
      const topPos = element.getBoundingClientRect().top + window.pageYOffset
      window.scrollTo({
        top: topPos,
        behavior: "smooth"
      });
    })
  }

  const burger = document.querySelector('.header__burger')

  if (burger) {
    burger.addEventListener('click', () => {
      const header = document.querySelector('.header-menu--mob')
      const body = document.querySelector('body')
      header.classList.toggle('open')
      body.classList.toggle('no-scroll')
    })
  }

  const closeMenuBtn = document.querySelector('.header__close')

  if (closeMenuBtn) {
    closeMenuBtn.addEventListener('click', () => {
      const header = document.querySelector('.header-menu--mob')
      const body = document.querySelector('body')
      header.classList.remove('open')
      body.classList.remove('no-scroll')
    })
  }

  initMarginMain();

  footerHandler(true);

  document.addEventListener('scroll', () => {
    footerHandler();
    menuHandler();
  })

  document.addEventListener('resize', () => {
    debounce(() => {
      initMarginMain();
      footerHandler();
    }, 30);
  })


});


function initMarginMain() {
  const footer = document.querySelector('.footer')
  if (footer) {
    let footerHeight = footer.clientHeight
    mainHandler(footerHeight)
  }
}

function mainHandler(marginBottom) {
  const main = document.querySelector('.main')
  main.style.marginBottom = marginBottom + 'px'
}

function menuHandler() {
  const headerDense = document.querySelector('.header__dense')
  const header = document.querySelector('.header')

  if (header.clientHeight < window.scrollY && !prevScrollY) {
    headerDense.classList.add('open')
    prevScrollY = window.scrollY;
    return
  }

  if (window.scrollY < prevScrollY && header.clientHeight < window.scrollY) {
    headerDense.classList.add('open')
  } else {
    headerDense.classList.remove('open')
  }

  prevScrollY = window.scrollY;
}

function getScrollHeight() {
  return Math.max(
    document.body.scrollHeight, document.documentElement.scrollHeight,
    document.body.offsetHeight, document.documentElement.offsetHeight,
    document.body.clientHeight, document.documentElement.clientHeight
  );
}


function footerHandler(init = false) {
  const footer = document.querySelector('.footer')

  if (init && footer) {
    const windowHeight = window.innerHeight;
    const bodyHeight = document.querySelector('body').getBoundingClientRect().height;

    if (windowHeight < bodyHeight) {
      return
    }
  }

  if (footer) {
    // let footerHeight = footer.clientHeight

    // console.log(window.innerHeight + window.pageYOffset, getScrollHeight())

    if ((window.innerHeight + window.pageYOffset + 20) >= getScrollHeight()) {

      // mainHandler(footerHeight)
      footer.classList.add('show')
    } else {
      // mainHandler(footerHeight)
      // footer.style.bottom = -footerHeight + 'px'
      footer.classList.remove('show')
    }
  }
}

function debounce(method, delay) {
  clearTimeout(method._tId);
  method._tId = setTimeout(function () {
    method();
  }, delay);
}


